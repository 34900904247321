import React from "react";
import { AppRouter } from './routers/AppRouter';
import "./App.css"


function App() {

  return (
      <div className="App">
        <AppRouter/>
      </div>
  );
}

export default App;




